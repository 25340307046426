import React from "react";

export const Prices = (props) => {
  return (
    <div id="prices" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Hinnakiri</h2>
        </div>

        <div className="price-table">
          <table>
            <thead>
              <tr>
                <th>Teenus</th>
                <th>Hind</th>
              </tr>
            </thead>
            <tbody>
              {props.data
                ? props.data.map((d, i) => (
                    <tr>
                      <div key={`${d.name}-${i}`}>
                        <td>{d.name}</td>
                      </div>
                      <td>{d.price}</td>
                    </tr>
                  ))
                : "Loading..."}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
