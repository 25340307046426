import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>Meeskond</h2>
            <p>
              Sotsiaalpedagoogidena on meie ülesandeks sotsiaalsete probleemide
              leevendamine ja pidurdamine ning heaolu edendamine. Koostöös
              erialaspetsialistidega toetame, suuname, juhendame ja abistame nii
              hariduslike, sotsiaalsete kui ka terapeutilistega tegevuste kaudu
              lapsi, noori ning eakaid, eesmärgiga edendada inimese
              funktsioneerimist ühiskonna täisväärtusliku liikmena.
            </p>
          </div>
        </div>

        {props.data
          ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`}>
                <div className="row">
                  <div className="col-xs-12 col-md-6"></div>
                  <div className="col-xs-12 col-md-6">
                    <h2>{d.name}</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <img src={d.img} className="img-profile" alt="" />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <div className="team-text">
                      <p>{d.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : "Loading..."}
      </div>
    </div>
  );
};
